import { useCallback, useEffect, useState } from 'react';
import '../App.css';
import About from '../components/About';
import Experiences from '../components/Experiences';
import Contact from '../components/Contact';
import MyCanvas from '../components/Canvas';

import { formatRichText, getComponentByName } from '../utils';

function Page({ blok }) {
    const fonts = getComponentByName('Fonts', blok);
    const home = getComponentByName('Home', blok);
    const about = getComponentByName('About', blok);
    const experiences = getComponentByName('Experiences', blok);
    const contacts = getComponentByName('Contacts', blok);
    const footer = getComponentByName('Footer', blok);

    const [pages, setPages] = useState([
        {
            title: 'about',
            page: () => <About blok={about} />,
        },
        {
            title: 'experiences',
            page: () => <Experiences blok={experiences} />,
        },
        {
            title: 'contact me',
            page: () => <Contact blok={contacts} />,
        },
    ]);
    const [moved, setMoved] = useState(false);
    const [smallHeader, setSmallHeader] = useState(false);
    const [translateX, setTranslateX] = useState('0');
    const [year, setYear] = useState(2024);
    const [pageName, setPageName] = useState('About');
    const [pageIndex, setPageIndex] = useState(0);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const isMobile = viewportWidth <= 800;

    const footerDescription = formatRichText(footer.descrizione);
    const footerDescriptionFormatted = footerDescription.__html.replace('{year}', year);

    const handleWheel = useCallback((event) => {
        if (event.deltaY > 10) {
            setMoved(true);
        } else if (event.deltaY < -100) {
            setMoved(false);
        }
    }, []);

    const handlePage = useCallback(
        (i, title) => {
            setMoved(true);
            setTranslateX(`calc(-100vw * ${i})`);
            setPageName(title);
            setPageIndex(i);
            if (setSmallHeader) {
                setSmallHeader(false);
            }
            const div = document.getElementById(`page-${i}`);
            div.scrollTo({ top: 0 });
        },
        [moved, translateX, pageName, pageIndex]
    );

    const handleHome = useCallback(() => {
        setSmallHeader(false);
        setMoved(false);
    }, [smallHeader, moved]);

    useEffect(() => {
        const year = new Date().getFullYear();
        setYear(year);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const div = document.getElementById(`page-${pageIndex}`);
            if (div.scrollTop > 30) {
                setSmallHeader(true);
            } else {
                setSmallHeader(false);
            }
        };

        const div = document.getElementById(`page-${pageIndex}`);
        div.addEventListener('scroll', handleScroll);

        return () => {
            div.removeEventListener('scroll', handleScroll);
        };
    }, [pageIndex]);

    useEffect(() => {
        // Funzione per aggiornare larghezza e altezza della viewport
        const updateViewportSize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        // Aggiungi un listener per il ridimensionamento della finestra
        window.addEventListener('resize', updateViewportSize);

        // Pulisci il listener quando il componente si dismonta
        return () => {
            window.removeEventListener('resize', updateViewportSize);
        };
    }, []);

    function startTransition() {
        var element = document.querySelector('.navbar');
        var finalValueRoundBottom = isMobile ? 20 : 70;
        var duration = 400; // Durata in millisecondi (1 secondo)
        var startTime = null;

        function animateClipPath(timestamp) {
            if (!startTime) startTime = timestamp;
            var progress = timestamp - startTime;
            var newValueRoundBottom = Math.round((progress / duration) * finalValueRoundBottom, finalValueRoundBottom);
            var newInitialValueRoundBottom = Math.round((progress / duration) * 0, 0);

            if (moved) {
                element.style.setProperty('--round-bottom', newValueRoundBottom + 'px');
                if (isMobile) {
                    element.style.setProperty('--round-top', newValueRoundBottom + 'px');
                }
            } else {
                element.style.setProperty('--round-bottom', newInitialValueRoundBottom + 'px');
                if (isMobile) {
                    element.style.setProperty('--round-top', newInitialValueRoundBottom + 'px');
                }
            }

            if (progress < duration) {
                window.requestAnimationFrame(animateClipPath);
            }
        }

        window.requestAnimationFrame(animateClipPath);
    }

    useEffect(() => {
        startTransition();
    }, [moved]);

    const css = `
    ${fonts.titles_font_url !== '' ? `@import url('${fonts.titles_font_url}');` : ''}
    ${fonts.paragraph_font_url !== '' ? `@import url('${fonts.paragraph_font_url}');` : ''}

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            ${fonts.titles_font_name !== '' ? `font-family: ${fonts.titles_font_name};` : 'font-family: "Neue Bold";'}
            ${fonts.titles_font_weight !== '' ? `font-weight: ${fonts.titles_font_weight};` : 'font-weight: bold;'}
            ${fonts.titles_font_size !== '' ? `font-size: ${fonts.titles_font_size}px;` : ''}
        }
        
        p,
        span,
        a,
        li {
            ${fonts.paragraph_font_name !== '' ? `font-family: '${fonts.titleparagraph_font_name}';` : 'font-family: "Neue Regular";'}
            ${fonts.paragraph_font_weight !== '' ? `font-weight: ${fonts.paragraph_font_weight};` : 'font-weight: regular;'}
            ${fonts.paragraph_font_size !== '' ? `font-size: ${fonts.paragraph_font_size}px;` : ''}
        }
    `;

    return (
        <div className='App' onWheelCapture={handleWheel}>
            <style>{css}</style>
            <div
                onClick={isMobile ? handleHome : () => {}}
                className={`navbar ${moved ? 'padding' : 'normalWidth'}`}
                style={{
                    height:
                        moved && !smallHeader && !isMobile
                            ? '400px'
                            : moved && smallHeader && !isMobile
                            ? '100px'
                            : moved && !smallHeader && isMobile
                            ? '43px'
                            : moved && smallHeader && isMobile
                            ? '43px'
                            : '100vh',
                    paddingTop: moved && !smallHeader && isMobile ? '0px' : moved && smallHeader && isMobile ? '0px' : '20px',
                }}
            >
                <div className='navHead'>
                    <img
                        onClick={handleHome}
                        style={{ cursor: 'pointer', paddingTop: moved && !smallHeader && isMobile ? '10px' : moved && smallHeader && isMobile ? '10px' : '0px' }}
                        src={home.logo.filename}
                        className='logoAndre'
                    />
                    <div className='nav-pages'>
                        <span onClick={() => handlePage(0, 'About')} className={`${moved ? 'nav-cta-background-off' : 'nav-cta-background'} nav-cta`}>
                            About
                        </span>
                        <span onClick={() => handlePage(1, 'Experiences')} className={`${moved ? 'nav-cta-background-off' : 'nav-cta-background'}  nav-cta`}>
                            Experiences
                        </span>
                    </div>
                    <span onClick={() => handlePage(2, 'Contact me')} className={`${moved ? 'nav-cta-background-off' : 'nav-cta-background'} nav-cta`}>
                        Contact Me
                    </span>
                </div>
                <div style={{ display: moved && !smallHeader ? 'flex' : 'none', marginTop: 140 }} className='title-ref'>
                    <h1>{pageName}</h1>
                </div>
                <h1 style={{ opacity: moved ? 0 : 1 }} className='title-home'>
                    {home.titolo}
                </h1>
                <div style={{ width: viewportWidth, height: viewportHeight }} className='splineContainer'>
                    <MyCanvas moved={moved} isMobile={isMobile} />
                </div>
            </div>
            <nav className='mobileNav'>
                <div>
                    <span
                        onClick={() => handlePage(0, 'About')}
                        style={{
                            backgroundColor: pageName === 'About' && moved ? '#000' : !moved ? 'hsla(0, 0%, 0%, 0.1)' : 'hsl(0, 0%, 85%)',
                            color: pageName === 'About' && moved ? '#fff' : !moved ? '#fff' : '#000',
                        }}
                        className='nav-cta'
                    >
                        About
                    </span>
                    <span
                        onClick={() => handlePage(1, 'Experiences')}
                        style={{
                            backgroundColor: pageName === 'Experiences' && moved ? '#000' : !moved ? 'hsla(0, 0%, 0%, 0.1)' : 'hsl(0, 0%, 85%)',
                            color: pageName === 'Experiences' && moved ? '#fff' : !moved ? '#fff' : '#000',
                        }}
                        className='nav-cta'
                    >
                        Experiences
                    </span>
                    <span
                        onClick={() => handlePage(2, 'Contact me')}
                        style={{
                            backgroundColor: pageName === 'Contact me' && moved ? '#000' : !moved ? 'hsla(0, 0%, 0%, 0.1)' : 'hsl(0, 0%, 85%)',
                            color: pageName === 'Contact me' && moved ? '#fff' : !moved ? '#fff' : '#000',
                        }}
                        className='nav-cta'
                    >
                        Contact me
                    </span>
                </div>
            </nav>
            <div style={{ width: '100vw' }}>
                <div style={{ transform: `translateX(${translateX}`, width: `calc(100vw * ${pages.length})` }} id='page-container' className='pageContainer'>
                    {pages.map((item, i) => {
                        const Page = item.page;
                        return (
                            <div key={i} id={`page-${i}`} className='page'>
                                <Page />
                                <div className='footer' dangerouslySetInnerHTML={{ __html: footerDescriptionFormatted }} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Page;
