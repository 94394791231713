import { useEffect } from 'react';
import './App.css';

import { useStoryblok, StoryblokComponent } from '@storyblok/react';

function App() {
    let slug = window.location.pathname === '/' ? 'home' : 'home';

    let params = { version: 'publish' };

    useEffect(() => {
        const isDraft = window.location.search.split('=')[0];
        const isLocalHost = window.location.host;
        if (isDraft === '?_storyblok' || isLocalHost === 'localhost:3000') {
            params.version = 'draft';
        }
    }, []);

    const story = useStoryblok(slug, params);
    if (!story || !story.content) {
        return <div></div>;
    }

    return <StoryblokComponent blok={story.content} />;
}

export default App;
