import React from 'react';
import '../styles/Page.css';
import { formatRichText } from '../utils';

function About({ blok }) {
    return (
        <div className='page_container'>
            <h2>{blok.titolo}</h2>
            <div className='page_frame'>
                <img src={blok.immagine_profilo.filename} />
                <div className='description'>
                    <div dangerouslySetInnerHTML={formatRichText(blok.descrizione)} />
                    <div className='socials'>
                        {blok.socials.map((item, i) => (
                            <a key={i} target='_blank' href={item.link.url}>
                                <img src={item.logo.filename} />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
