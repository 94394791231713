export const getComponentByName = (name, blok) => {
    const index = blok?.body?.findIndex((e) => e.component === name);
    return blok.body[index];
};

export const formatRichText = (text) => {
    const StoryblokClient = require('storyblok-js-client');

    let Storyblok = new StoryblokClient({
        accessToken: 'wxrYdHGQJItCrW3Bel2hFwtt',
    });
    return { __html: Storyblok.richTextResolver.render(text) };
};
