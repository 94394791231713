import React from 'react';
import '../styles/InfiniteScroll.css';

function InfiniteScroll({ partners }) {
    const isMobile = window.innerWidth <= 800;
    const speed = 10000;
    const mobileSpeed = 10000;
    return (
        <>
            <div className='inner'>
                <div className='wrapper'>
                    <section style={{ '--speed': `${isMobile ? mobileSpeed : speed}ms` }}>
                        {partners.map((item, index) => {
                            return (
                                <>
                                    {item.link.url === '' ? (
                                        <a key={index}>
                                            <div className='image' key={index}>
                                                <img src={item.logo.filename} alt='alt' />
                                            </div>
                                        </a>
                                    ) : (
                                        <a key={index} target='_blank' href={`${item.link.url}`}>
                                            <div className='image' key={index}>
                                                <img src={item.logo.filename} alt='alt' />
                                            </div>
                                        </a>
                                    )}
                                </>
                            );
                        })}
                    </section>
                    <section style={{ '--speed': `${isMobile ? mobileSpeed : speed}ms` }}>
                        {partners.map((item, index) => {
                            return (
                                <>
                                    {item.link.url === '' ? (
                                        <a key={index}>
                                            <div className='image' key={index}>
                                                <img src={item.logo.filename} alt='alt' />
                                            </div>
                                        </a>
                                    ) : (
                                        <a key={index} target='_blank' href={`${item.link.url}`}>
                                            <div className='image' key={index}>
                                                <img src={item.logo.filename} alt='alt' />
                                            </div>
                                        </a>
                                    )}
                                </>
                            );
                        })}
                    </section>
                    <section style={{ '--speed': `${isMobile ? mobileSpeed : speed}ms` }}>
                        {partners.map((item, index) => {
                            return (
                                <>
                                    {item.link.url === '' ? (
                                        <a key={index}>
                                            <div className='image' key={index}>
                                                <img src={item.logo.filename} alt='alt' />
                                            </div>
                                        </a>
                                    ) : (
                                        <a key={index} target='_blank' href={`${item.link.url}`}>
                                            <div className='image' key={index}>
                                                <img src={item.logo.filename} alt='alt' />
                                            </div>
                                        </a>
                                    )}
                                </>
                            );
                        })}
                    </section>
                </div>
            </div>
        </>
    );
}

export default InfiniteScroll;
