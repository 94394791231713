import React, { useEffect, useState } from 'react';
import '../styles/Experiences.css';
import InfiniteScroll from './InfiniteScroll';

function Experiences({ blok }) {
    return (
        <>
            <div className='experiencesGrid'>
                {blok.esperienze.map((item, i) => (
                    <a key={i} target='_blank' style={{ pointerEvents: item.link.url !== '' ? 'unset' : 'none' }} href={item.link.url}>
                        <div className='experiencesImg'>
                            <img src={item.logo.filename} />
                        </div>
                        <div>
                            <h2>{item.nome}</h2>
                            {item.ruoli > 1 ? (
                                <div>
                                    {item.ruoli.map((item, i) => (
                                        <div key={i}>
                                            <p className='date'>{item.periodo}</p>
                                            <p>{item.ruolo}</p>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <>
                                    {item.ruoli.map((item, i) => (
                                        <>
                                            <p className='date'>{item.periodo}</p>
                                            <p>{item.ruolo}</p>
                                        </>
                                    ))}
                                </>
                            )}
                        </div>
                    </a>
                ))}
            </div>
            <p style={{ marginTop: 100 }}>
                <b>In Collaboration With:</b>
            </p>
            <InfiniteScroll partners={blok.partners} />
        </>
    );
}

export default Experiences;
