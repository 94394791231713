import React from 'react';
import '../styles/Contact.css';
import { ReactComponent as Camera } from '../images/camera.svg';
import { ReactComponent as Mail } from '../images/mail.svg';
import { formatRichText } from '../utils';

function Contact({ blok }) {
    return (
        <div className='mainContact'>
            <div dangerouslySetInnerHTML={formatRichText(blok.paragrafo)} />
            <div className='contact'>
                <a className='meetBtn' href={`mailto:${blok.email}`}>
                    <div className='iconContainer'>
                        <Mail className='abotIcon' style={{ height: 33, zIndex: 2 }} />
                        <span className='gradienticon' />
                    </div>
                    <p style={{ fontSize: '1.5rem' }}>
                        <b>{blok.email_button_title}</b>
                    </p>
                    <p className='subHeadline'>{blok.email_button_subheadline}</p>
                    <span className='meetBtnBg' />
                </a>
                <a className='meetBtn' href={blok.meet.url}>
                    <div className='iconContainer'>
                        <Camera className='abotIcon' style={{ height: 30, zIndex: 2 }} />
                        <span className='gradienticon' />
                    </div>
                    <p style={{ fontSize: '1.5rem' }}>
                        <b>{blok.meet_button_title}</b>
                    </p>
                    <p className='subHeadline'>{blok.meet_button_subheadline}</p>
                    <span className='meetBtnBg' />
                </a>
            </div>
        </div>
    );
}

export default Contact;
