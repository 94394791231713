import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { useControls } from 'leva';
import { LayerMaterial, Depth, Fresnel } from 'lamina';
import { EffectComposer, Noise, Bloom } from '@react-three/postprocessing';
import { BlendFunction, BlurPass, Resizer, KernelSize, Resolution } from 'postprocessing';

import * as THREE from 'three';

function MyCanvas({ moved, isMobile }) {
    return (
        <Canvas gl={{ antialias: true, pixelRatio: window.devicePixelRatio }} style={{ width: '100%', height: '100%', filter: moved ? 'blur(10px)' : 'blur(0px)', cursor: 'grab' }}>
            <Suspense fallback={null}>
                <EffectComposer>
                    <Noise premultiply opacity={0.2} blendFunction={BlendFunction.ADD} />
                    <Bloom
                        intensity={0.2} // The bloom intensity.
                        blurPass={undefined} // A blur pass.
                        kernelSize={KernelSize.LARGE} // blur kernel size
                        luminanceThreshold={0.5} // luminance threshold. Raise this value to mask out darker elements in the scene.
                        luminanceSmoothing={0.005} // smoothness of the luminance threshold. Range is [0, 1]
                        mipmapBlur={false} // Enables or disables mipmap blur.
                        resolutionX={Resolution.AUTO_SIZE} // The horizontal resolution.
                        resolutionY={Resolution.AUTO_SIZE} // The vertical resolution.
                    />
                </EffectComposer>
                <OrbitControls enableZoom={false} />
                {/* <gridHelper /> */}
                <Sphere isMobile={isMobile} />
                <Plane />
            </Suspense>
        </Canvas>
    );
}

function Plane() {
    const ref = useRef();
    // const { gradient } = useControls({ gradient: { value: 0.6, min: 0, max: 1 } });
    let gradient = 0.31;

    const scale = 6;
    // Animate gradient
    useEffect(() => {
        const interval = setInterval(() => {
            numberProgress();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    useFrame((state) => {
        gradient = Math.random() * 0.9 + 0.1;

        const sin = Math.sin(state.clock.elapsedTime * 1);
        const cos = Math.cos(state.clock.elapsedTime * 1);
        ref.current.layers[0].origin.set(cos / 2, 0, 0);
        ref.current.layers[1].origin.set(cos, sin, cos);
        ref.current.layers[2].origin.set(sin, cos, sin);
        ref.current.layers[3].origin.set(cos, sin, cos);
    });

    let isIncreasing = true;

    const numberProgress = useCallback(() => {
        const startLightness = {
            0: { h: 330, s: 100, l: 50 },
            1: { h: 30, s: 100, l: 50 },
            2: { h: 330, s: 100, l: 25 },
            3: { h: 0, s: 0, l: 100 },
            4: { h: 0, s: 0, l: 40 },
        };

        const duration = 1000;
        let startTime = null;

        function animateColors(timestamp) {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;

            const interpolationRatio = Math.min(progress / duration, 1);

            Object.keys(startLightness).forEach((key) => {
                //  0 : 0 + 50
                const deltaL = isIncreasing ? 0 : startLightness[key].l;

                //  50 - 50
                const newLightness = isIncreasing ? 0 + startLightness[key].l * interpolationRatio : startLightness[key].l - deltaL * interpolationRatio;

                // ref.current.layers[key].colorA.set(`hsl(${startLightness[key].h}, ${startLightness[key].s}%, ${newLightness}%)`);
                if (key === '4') {
                    // Update the Fresnel color
                    ref.current.layers[4].color.set(`hsl(${startLightness[key].h}, ${startLightness[key].s}%, ${newLightness}%)`);
                } else {
                    ref.current.layers[key].colorA.set(`hsl(${startLightness[key].h}, ${startLightness[key].s}%, ${newLightness}%)`);
                }
            });

            if (progress < duration) {
                window.requestAnimationFrame(animateColors);
            }
        }
        isIncreasing = !isIncreasing;

        window.requestAnimationFrame(animateColors);
    }, []);

    return (
        <mesh>
            <sphereGeometry args={[scale, scale, scale, 64]} />
            <LayerMaterial side={THREE.BackSide} ref={ref} toneMapped={false}>
                <Depth colorA='hsl(330, 100%, 50%)' colorB='black' alpha={1} mode='normal' near={scale * 0.5 * gradient} far={scale * 0.5} origin={[0, 2, 20]} />
                <Depth colorA='hsl(30, 100%, 50%)' colorB='red' alpha={1} mode='add' near={scale * 2 * gradient} far={scale * 2} origin={[0, 3, 0]} />
                <Depth colorA='hsl(330, 100%, 25%)' colorB='black' alpha={1} mode='add' near={scale * 3 * gradient} far={scale * 3} origin={[0, 2, 0]} />
                <Depth colorA='hsl(0, 0%, 100%)' colorB='red' alpha={1} mode='overlay' near={scale * 1.5 * gradient} far={scale * 1.5} origin={[0, 0, 1]} />
                <Fresnel mode='add' color='hsl(0, 0%, 40%)' intensity={0.5} power={1.5} bias={0.05} />
            </LayerMaterial>
        </mesh>
    );
}

function Sphere({ isMobile }) {
    const ref = useRef();

    useEffect(() => {
        const interval = setInterval(() => {
            colorChange();
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    // const { gradient } = useControls({ gradient: { value: 0.6, min: 0, max: 1 } });

    const gradient = 0.6;

    const scale = isMobile ? 1 : 1.3;

    // Animate gradient
    useFrame((state) => {
        const sin = Math.sin(state.clock.elapsedTime * 1.2);
        const cos = Math.cos(state.clock.elapsedTime * 1.2);
        ref.current.layers[0].origin.set(cos / 2, 0, 0);
        ref.current.layers[1].origin.set(cos, sin, cos);
        ref.current.layers[2].origin.set(sin, cos, sin);
        ref.current.layers[3].origin.set(cos, sin, cos);
    });

    let isIncreasing = true;

    const colorChange = useCallback(() => {
        const startLightness = {
            0: { h: 330, s: 100, l: 50 },
            1: { h: 241, s: 100, l: 50 },
            2: { h: 145, s: 100, l: 25 },
            3: { h: 0, s: 0, l: 100 },
        };

        const duration = 1000;
        let startTime = null;

        function animateColors(timestamp) {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;

            const interpolationRatio = Math.min(progress / duration, 1);

            Object.keys(startLightness).forEach((key) => {
                //  0 : 0 + 50
                const deltaL = isIncreasing ? 0 : startLightness[key].l;

                //  50 - 50
                const newLightness = isIncreasing ? 0 + startLightness[key].l * interpolationRatio : startLightness[key].l - deltaL * interpolationRatio;

                ref.current.layers[key].colorA.set(`hsl(${startLightness[key].h}, ${startLightness[key].s}%, ${newLightness}%)`);
            });

            if (progress < duration) {
                window.requestAnimationFrame(animateColors);
            }
        }
        isIncreasing = !isIncreasing;

        window.requestAnimationFrame(animateColors);
    }, []);

    return (
        <mesh scale={scale} position={[0, 0, 0]}>
            <torusKnotGeometry args={[1, 0.4, 140, 20]} />
            <LayerMaterial ref={ref} toneMapped={false}>
                <Depth colorA='hsl(330, 100%, 50%)' colorB='black' alpha={1} mode='normal' near={scale * 0.5 * gradient} far={scale * 0.5} origin={[0, 0, 0]} />
                <Depth colorA='hsl(241, 100%, 50%)' colorB='yellow' alpha={1} mode='add' near={scale * 2 * gradient} far={scale * 2} origin={[0, 0, 0]} />
                <Depth colorA='hsl(145, 100%, 25%)' colorB='#f7b955' alpha={1} mode='add' near={scale * 3 * gradient} far={scale * 3} origin={[0, 0, 0]} />
                <Depth colorA='white' colorB='red' alpha={1} mode='overlay' near={scale * 1.5 * gradient} far={scale * 1.5} origin={[0, 0, 0]} />
                <Fresnel mode='add' color='white' intensity={0.5} power={1.5} bias={0.05} />
            </LayerMaterial>
        </mesh>
    );
}

export default MyCanvas;
